.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: white;
  gap: 3em;
}

h1 {
  color: #61dafb;
}

p {
  font-size: large;
  margin: 0;
}

.status p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.c {
  font-size: 0.75em;
}

.c-unset {
  color: #808080;
  /* margin-top: 1em; */
}

.bloc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
}

.bloc h2 {
  margin: 0;
  margin-bottom: 0.5em;
  color: #42a4bf;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
}

.elem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
  flex: 1;
}

.infos {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3em;
}

.c-red {
  color: #c84040;
}

.c-green {
  color: #40c840;
}

.c-yellow {
  color: #c8a640;
}

.buttonsControllers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  background-color: #42a4bf;
  color: white;
  cursor: pointer;
  border: none;
}

.btn.btn-connect {
  background-color: #61dafb;
}

.btn:enabled:active {
  background-color: #36849a;
}

.btn:disabled {
  opacity: 0.5;
  cursor: default;
}

li {
  display: block;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 1em;
}

.rules-list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.dummiesContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.25em;
  flex-wrap: nowrap;
  max-width: 250px;
}

.dummiesList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25em;
}

.dummy {
  border: 1px solid #42a4bf;
  border-radius: 11px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 10px;
  gap: 0.25em;
}

.dummiesListContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
}

.bg-green {
  background-color: #40c840;
  /* color: #000000; */
}

.bg-red {
  background-color: #c84040;
  /* color: #000000; */
}

.scan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
}

.flex-fill {
  flex: 1;
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.gap-1 {
  gap: 0.5em;
}

.w-100 {
  width: 100%;
}

.py-2 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.px-2 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.text-align-end {
  text-align: end;
}

input {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, .05);
  /* max-width: 100%;
  width: 100%; */
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 0.375em;
  color: #363636;
  padding: 1px 2px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

input:hover {
  border-color: #b5b5b5;
}

input:focus {
  border-color: #61dafb;
}

.alert {
  padding: 0.7em;
  background: #808080;
  border-radius: 0.5em;
  font-size: small;
  color: black;
  border: 1px solid #111111;
  display: flex;
  justify-content: space-between;
}

.alert.alert-danger {
  color: rgb(225, 151, 149);
  background-color: rgb(72, 29, 31);
  border-color: rgb(104, 37, 41);
}

.alert button {
  border: none;
  background: none;
  padding: 0em 0.3em 0 0.7em;
  font-size: 0.9em;
  color: rgb(225, 151, 149);
  font-weight: 100;
  cursor: pointer;
}

.mt-1 {
  margin-top: 1em;
}